import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const SinglePageLeadGenJourney = loadable(() => import('/src/forms/LeadGen/SinglePageLeadGenJourney'))

const InsuranceHeroLeadGenJourney = ({ title }) => {
    return (
        <SinglePageLeadGenJourney
            title={title}
            leadSource={'SelfGen.CTQ.InsuranceHero'}
            firebaseCollection={'insurance-hero-single-page'}
            successPage={'/insurance-hero-success'}
        />
    )
}

InsuranceHeroLeadGenJourney.propTypes = {
    title: PropTypes.string.isRequired
}

export default InsuranceHeroLeadGenJourney
